<template>
  <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-row>
      <b-col cols="12">
        <top-menu :data="topMenu" @update="handlerMenuListele" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <orta-menuler
          :data="kategoriler"
          :props="{ title: 'Kategoriler', type: 'kategori' }"
          @menuEkle="handlerMenuEkle($event)"
        />
      </b-col>
      <b-col md="4">
        <orta-menuler :data="sayfalar" :props="{ title: 'Sayfalar', type: 'sayfalar' }" @menuEkle="handlerMenuEkle($event)" />
      </b-col>
      <b-col md="4">
        <!-- <orta-menuler :data="galeriler" :props="{ title: 'Galeri', type: 'galeri' }" @menuEkle="handlerMenuEkle($event)" /> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <bottom-menu :data="bottomMenu" @update="handlerMenuListele" />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import OrtaMenuler from './component/ortaMenuler.vue';
import TopMenu from './component/topMenu.vue';
import BottomMenu from './component/bottomMenu.vue';

import { ref, onMounted } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';

export default {
  components: {
    OrtaMenuler,
    TopMenu,
    BottomMenu,
  },

  setup() {
    const expo = {};
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.show = ref(false);
    expo.topMenu = ref([]);
    expo.bottomMenu = ref([]);
    expo.kategoriler = ref([]);
    expo.sayfalar = ref([]);
    expo.galeriler = ref([]);
    expo.turlar = ref([]);
    expo.form = ref({});

    onMounted(async () => {
      expo.show.value = true;
      expo.kategoriler.value = [];
      expo.sayfalar.value = [];
      await store.dispatch('menuListele');
      await store.dispatch('kategoriListele');
      await store.dispatch('sayfaListele');
      await store.dispatch('galeriListele');

      expo.handlerMenuListele();

      const kat = await store.getters.getKategoriler;

      kat.forEach((el) => {
        if (el.kategori_k_no == null) expo.kategoriler.value.push({ ...el, baslik: el.icerik[expo.defaultDil.value].baslik });
      });

      const sayfa = await store.getters.getSayfalar;

      sayfa.forEach((el) => {
        expo.sayfalar.value.push({ ...el, baslik: el.icerik[expo.defaultDil.value].baslik });
      });

      const galeri = await store.getters.getGaleriler;

      galeri.forEach((el) => {
        expo.galeriler.value.push({ ...el, baslik: el.icerik[expo.defaultDil.value].baslik });
      });

      expo.show.value = false;
    });

    expo.handlerMenuEkle = (event) => {
      const item = event.item;
      if (event.type === 'kategori') {
        expo.form.value.konum = event.location;
        expo.form.value.menu_k_no = item.k_no;
        expo.form.value.tip = event.type;
      } else if (event.type === 'sayfalar') {
        expo.form.value.konum = event.location;
        expo.form.value.menu_k_no = item.k_no;
        expo.form.value.tip = event.type;
      } else if (event.type === 'galeri') {
        expo.form.value.konum = event.location;
        expo.form.value.menu_k_no = item.k_no;
        expo.form.value.tip = event.type;
      } else {
      }
      expo.handlerMenuKaydet();
    };

    expo.handlerMenuKaydet = () => {
      store
        .dispatch('menuEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.handlerMenuListele();
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.data.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    expo.handlerMenuListele = () => {
      expo.show.value = true;
      const menuler = store.getters.getMenuListele;
      expo.topMenu.value = [];
      expo.bottomMenu.value = [];
      menuler.map((el) => {
        console.log(el);
        el.konum == 'top' ? expo.topMenu.value.push(el) : expo.bottomMenu.value.push(el);
      });
      expo.show.value = false;
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
